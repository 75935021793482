import React from 'react';

import { Layout } from '../components';

const NotFoundPage = () => {
  return (
    <Layout bodyClass="min-h-screen flex flex-col items-center justify-center">
      <h1 className="mb-10">Not Found</h1>
      <p>You just hit a page that doesn&#39;t exist...</p>
    </Layout>
  );
};

export default NotFoundPage;
